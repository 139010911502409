.font-Avenir.font-light {
  font-family: "Avenir", sans-serif;
  font-weight: 300;
}

@font-face {
  font-family: "Avenir";
  src: url("../../fonts/avenirltstd-black-webfont.woff2") format("woff2"),
    url("../../fonts/avenirltstd-black-webfont.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Avenir";
  src: url("../../fonts/avenirltstd-blackoblique-webfont.woff2") format("woff2"),
    url("../../fonts/avenirltstd-blackoblique-webfont.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Avenir";
  src: url("../../fonts/avenirltstd-heavy-webfont.woff2") format("woff2"),
    url("../../fonts/avenirltstd-heavy-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Avenir";
  src: url("../../fonts/avenirltstd-heavyoblique-webfont.woff2") format("woff2"),
    url("../../fonts/avenirltstd-heavyoblique-webfont.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Avenir";
  src: url("../../fonts/avenirltstd-light-webfont.woff2") format("woff2"),
    url("../../fonts/avenirltstd-light-webfont.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Avenir";
  src: url("../../fonts/avenirltstd-lightoblique-webfont.woff2") format("woff2"),
    url("../../fonts/avenirltstd-lightoblique-webfont.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Avenir";
  src: url("../../fonts/avenirltstd-medium-webfont.woff2") format("woff2"),
    url("../../fonts/avenirltstd-medium-webfont.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Avenir";
  src: url("../../fonts/avenirltstd-mediumoblique-webfont.woff2")
      format("woff2"),
    url("../../fonts/avenirltstd-mediumoblique-webfont.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Avenir";
  src: url("../../fonts/avenirltstd-oblique-webfont.woff2") format("woff2"),
    url("../../fonts/avenirltstd-oblique-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
