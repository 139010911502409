.white {
  color: $color-white;
}
.dark-white {
  color: $color-dark;
}
.primary {
  color: $color-primary;
}
.secondary {
  color: $color-secondary;
}
.dark {
  color: $color-dark;
}
.title {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
}
.col-title {
  padding: 0 30px;
}
html,body {
  height: 100%;
  scroll-padding: 5rem;
}
body {
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

body::after{
  position:absolute; width:0; height:0; overflow:hidden; z-index:-1; // hide images
  content:url("../img/date-bg1-mb.jpg") url("../img/date-bg2-mb.jpg") url("../img/date-bg3-mb.jpg") url("../img/date-bg4-mb.jpg") url("../img/date-bg1.jpg") url("../img/date-bg2.jpg") url("../img/date-bg3.jpg") url("../img/date-bg4.jpg") ;   // load images
}

html {
  height: -webkit-fill-available;
}