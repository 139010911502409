.sponsor {
  padding: 0 1rem;
  .container {
    padding: 2rem;
    border-radius: 30px;
    border: 1px solid rgba(112, 112, 112, 0.3);
  }
  &.mb {
    @media (min-width: 991.98px) {
      display: none;
    }
  }
  &.d {
    @media (max-width: 991.98px) {
      display: none;
    }
  }
  font-family: "Rubik" !important;
  .wrapper {
    background-color: $color-secondary-fade1;
    border-radius: 20px;
    min-height: 270px !important;
  }
  .caption-text {
    width: 50%;
    @media (max-width: 991.98px) {
      width: 100%;
    }
  }
  &-title {
    margin: auto 0;
    margin-bottom: 2rem;
    h3 {
      @include font-size(30px);
      color: $color-grey;
      text-transform: uppercase;
      line-height: 150%;
      display: block;
    }
    .separator {
      background: $color-primary;
      margin: 10px auto;
      border: none;
      border-radius: 40px;
      width: 4.125rem;
      height: 6px;
      display: block;
    }
  }
  @media (max-width: 991.98px) {
    img {
      margin: auto;
    }
  }

  .logo1 {
    width: 3rem;
    @media (max-width: 991.98px) {
      width: 5.0625rem;
    }
  }
  .logo2 {
    width: 3.1875rem;
    @media (max-width: 991.98px) {
      width: 5.0625rem;
    }
  }
  .logo3 {
    width: 4.0625rem;
    @media (max-width: 991.98px) {
      width: 5.0625rem;
    }
  }
  .logo4 {
    width: 2.6875rem;
    @media (max-width: 991.98px) {
      width: 3.6875rem;
    }
  }
  .logo5 {
    width: 5.375rem;
    @media (max-width: 991.98px) {
      width: 5.0625rem;
    }
  }

  p {
    color: $color-dark;
    font-size: 0.75rem;
    font-weight: 500;
    margin: 0;
    margin-left: 1rem;
    @media (max-width: 991.98px) {
      font-size: 0.9375rem;
    }
  }
  .big-text {
    font-size: 1.3125rem;
    @media (max-width: 991.98px) {
      font-size: 1.625rem;
    }
  }
  .text-gold {
    color: #c39c34;
  }
  .zone2 {
    display: flex;
    max-width: 18.21625rem;
    @media (max-width: 991.98px) {
      max-width: none;
    }
  }
  .zone3 {
    max-width: 18.4375rem;
    @media (max-width: 991.98px) {
      max-width: none;
    }
  }
  .zone4 {
    max-width: 17.625rem;
    @media (max-width: 991.98px) {
      max-width: none;
    }
  }
  .zone5 {
    max-width: 24.602rem;
    line-height: 120%;
  }
  .gap {
    gap: 2rem;
  }
}
