.event {
  font-family: "Rubik";
  padding-bottom: 8rem;
  h2 {
    color: $color-primary;
    font-size: 1.813rem;
    font-weight: bold;
  }
  hr {
    border: 0.25rem solid $color-primary;
    border-radius: 99999rem;
  }
  .text  {
    @include font-size(20px);
    line-height: 125%;
    color: $color-darker;
    position: relative;
  }
  .f-letter {
    @include font-size(38px);
  }
  &-content {
    max-width: 30.25rem;
  }
  &-container {
    position: relative;
    width: 100%;
    min-height: 33.5625rem;
    .credit {
      position: absolute;
      bottom: 15%;
      right: 5%;
      font-size: 0.625rem;
      z-index: 10;
      margin-bottom: 0;
      color: $color-white;
      @media (min-width: 768px) {
        bottom: 5%;
        right: 5%;
      }
    }
    img {
      position: absolute;
      top: -10%;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: left;
      z-index: -1;
      border-radius: 1.875rem;
      // mask-image: linear-gradient(0deg, #fff 70%, transparent 90%);
      @media (min-width: 768px) {
        // mask-image: linear-gradient(270deg, #fff 70%, transparent 90%);
        left: -20%;
        width: 120%;
        top: 0;
      }
    }
  }

  .separator {
    background: $color-primary;
    border: none;
    border-radius: 40px;
    width: 3.5625rem;
    height: 8px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    @media (min-width: 768px) {
      width: 8rem;
    }
  }

  #colombe {
    position: relative;
    padding-bottom: 16rem;
    &::after {
      width: 274.33px;
      height: 192.54px;
      position: absolute;
      bottom: 0%;
      z-index: -1;
      left: 0%;
      transform: translateY(65%);
      content: "";
      background: url("../img/colombe.svg") no-repeat;
      background-size: cover;
    }
  }
}
