.graph {
  &.mb {
    @media (min-width: 991.98px) {
      display: none;
    }
  }
  &.d {
    @media (max-width: 991.98px) {
      display: none;
    }

    & .graph-container {
      max-width: 50.8125rem;
    }
  }
  font-family: "Rubik" !important;
  .wrapper {
    background-color: $color-secondary-fade1;
    border-radius: 20px;
  }
  .caption-text {
    width: 50%;
    @media (max-width: 991.98px) {
      width: 100%;
      transform: translateX(-10%);
    }
  }
  &-title {
    margin: auto 0;
    width: 100%;
    max-width: 17.063rem;
    h3 {
      @include font-size(30px);
      color: $color-grey;
      text-transform: uppercase;
      line-height: 150%;
      display: block;
    }
    .separator {
      background: $color-primary;
      margin: 20px 0px;
      border: none;
      border-radius: 40px;
      width: 20%;
      height: 6px;
      margin: 15px 0px;
      display: block;
    }
  }
  &-circle {
    margin: auto 0;
    min-height: 200px;
    height: 200px;
  }
  &-caption {
    margin: auto 0;
    min-height: 200px;
    flex-direction: wrap;
    .caption-text {
      display: flex;
      margin: auto 0;
    }
    h3 {
      font-size: 1.625rem;
      text-align: right;
      font-weight: bold;
      width: 25%;
      margin-bottom: auto;
      margin: auto 0;
      @media (max-width: 991.98px) {
        width: 50%;
      }
    }
    p {
      color: $color-dark;
      font-size: 0.75rem;
      line-height: 0.75rem;
      margin: auto 0;
      @media (max-width: 991.98px) {
        width: 50%;
      }
    }
    .t1 {
      color: #C39C34;
    }
    .t2 {
      color: #ADA9A9;
    }
    .t3 {
      color: #F2D995;
    }
    .t4 {
      color: #D1AE51;
    }
    .t5 {
      color: #8E6B0C;
    }
  }
}
