header {
  position: relative;
  height: 85vh;
  background-image: url("../img/header-background.jpg");
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  color: $color-white;
  margin-top: 8vh;
  left: 0;
  z-index: 1;
  &::after {
    position: absolute;
    content: "";
    background: rgb(0, 0, 0);
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0.43%,
      rgba(0, 0, 0, 1) 100%
    );
    left: 0;
    z-index: -1;
    bottom: 0;
    width: 100%;
    height: 50%;
  }
  .credit {
    font-size: 0.625rem;
    position: absolute;
    color: $color-white;
    right: 0.5rem;
    bottom: 0;
  }
  .container {
    @media (max-width: 768px) {
      width: 100% !important;
      max-width: 100vw !important;
    }
  }
  @media (max-width: 768px) {
    background-image: url("../img/header-background-mb.jpg");
    background-position: 23% 0;
  }
  .container {
    @media (max-width: 768px) {
      margin-left: 0;
      margin-right: 0;
    }
  }
  p {
    font-size: 1.375rem;
    text-align: center;
    font-family: "Rubik";
    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }
  .text {
  }
  h3 {
    @media (max-width: 768px) {
      @include font-size(23px);
      line-height: 26px;
    }
    @include font-size(29px);
    align-self: center;
    width: 100%;
    text-align: center;
    font-family: "Rubik";
    color: $color-white;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    margin-bottom: 5px;
  }
  h1 {
    @media (max-width: 768px) {
      font-size: 1.7rem;
      padding: 20px 20px 0px 20px;
    }
    font-size: 3.875rem;
    width: 100%;
    text-align: center;
    font-family: "Rubik";
    color: $color-white;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    margin-bottom: 30px;
  }
  .underline {
    width: 10%;
    height: 8px;
    display: block;
    background-color: $color-primary;
    border-radius: 28px;
    margin-left: auto;
    margin-right: auto;
    border: none;
    @media (max-width: 768px) {
      font-size: 1.5rem;
      padding: 20px 20px 0px 20px;
    }
  }
  #header-bottom-arrow {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
    width: 100px;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 768px) {
      bottom: 20px;
    }
  }
  button.play {
    background: none;
    border: none;
    margin-bottom: 2rem;
    & img {
      cursor: pointer;
    }
    &:hover {
      transform: scale(1.1);
      transition-property: all;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 0.3s;
    }
  }
  .player {
    display: none;
    position: absolute;
    top: 5%;
    left: 10%;
    width: 80%;
    height: 80%;
    z-index: 9999;
  }
  .close {
    margin-bottom: 1rem;
    color: white;
    box-shadow: none;
    opacity: 1;
    text-shadow: none;
    font-size: 1.1rem;
  }
}
