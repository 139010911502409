#parole {
  padding: 3rem 0.75rem;

  h2 {
    font-size: 2.1875rem;
    @media (min-width: 768px) {
      font-size: 1.5625rem;
    }
    font-weight: 700;
    margin-bottom: 1rem;
    text-align: center;
    color: $color-primary;
  }

  .underline {
    width: 10%;
    height: 8px;
    display: block;
    background-color: $color-primary;
    border-radius: 28px;
    margin-left: auto;
    margin-right: auto;
    border: none;
  }

  p {
    max-width: 37.5rem;
    font-size: 1.125rem;
    color: $color-darker;
    text-align: center;
    margin: auto;
  }
}
