.donate {
  font-family: "Rubik" !important;
  background-color: $color-gray;

  .op-0 {
    opacity: 0;
  }
  .intro {
    text-align: center;
    position: relative;
    background-color: $color-white;
    border-radius: 1.875rem;
    padding: 2rem;
    @media (min-width: 768px) {
      padding: 2rem;
    }

    .title {
      font-weight: 500;
      font-size: 1.25rem;
      @media (min-width: 768px) {
        font-size: 1.875rem;
      }
    }
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 4.5rem;
      height: 4.375rem;
    }
    .separator {
      background: $color-red;
      border: none;
      border-radius: 40px;
      width: 3.1875rem;
      height: 4px;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .card {
    padding-top: 30px;
    background: $color-secondary-fade;
    width: calc(50% - 0.5rem);
    max-width: 13.25rem;
    border: none;
    border-radius: 20px;
    text-align: center;
    font-family: "Rubik";
    color: $color-dark;
    min-height: 166px;
    cursor: pointer;
    position: relative;
    h3 {
      @include font-size(40px);
    }
    p {
      @include font-size(18px);
      b {
        color: $color-red;
      }
    }
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      padding: 10px 0px;
      height: 8%;
      opacity: 0;
      width: 10.62%;
    }
    transition: 0.2s ease-in-out 0s;
    &:hover {
      color: $color-white;
      background-color: $color-secondary;
      box-shadow: 0px 0px 26px 6px rgba(0, 0, 0, 0.38);
      b {
        color: $color-white;
      }
      img {
        opacity: 1;
      }
    }
    &.active {
      color: $color-white;
      background-color: $color-secondary;
      box-shadow: 0px 0px 26px 6px rgba(0, 0, 0, 0.38);
      b {
        color: $color-white;
      }
      img {
        opacity: 1;
      }
    }
    &.custom {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      label {
        @include font-size(30px);
        width: 15%;
        line-height: 25px;
        text-align: center;
      }
      input {
        border-radius: 21px;
        font-family: "Rubik";
        text-align: center;
        @include font-size(16px);
        border: none;
        padding: 10px;
        width: 75%;
        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: #c39c34;
          text-align: center;
          @include font-size(16px);
          font-family: "Rubik";
          text-transform: uppercase;
          opacity: 1;
        }
        &::-moz-placeholder {
          /* Firefox 19+ */
          color: #c39c34;
          text-align: center;
          @include font-size(16px);
          font-family: "Rubik";
          text-transform: uppercase;
          opacity: 1;
        }
        &:-ms-input-placeholder {
          /* IE 10+ */
          color: #c39c34;
          text-align: center;
          @include font-size(16px);
          font-family: "Rubik";
          text-transform: uppercase;
          opacity: 1;
        }
        &:-moz-placeholder {
          /* Firefox 18- */
          color: #c39c34;
          text-align: center;
          @include font-size(16px);
          font-family: "Rubik";
          text-transform: uppercase;
          opacity: 1;
        }
        @media (max-width: 991px) {
          @include font-size(16px);
          border: none;
          padding: 10px;
          width: 75%;
          &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: #c39c34;
            text-align: center;
            @include font-size(14px);
            font-family: "Rubik";
            text-transform: uppercase;
            opacity: 1;
          }
          &::-moz-placeholder {
            /* Firefox 19+ */
            color: #c39c34;
            text-align: center;
            @include font-size(14px);
            font-family: "Rubik";
            text-transform: uppercase;
            opacity: 1;
          }
          &:-ms-input-placeholder {
            /* IE 10+ */
            color: #c39c34;
            text-align: center;
            @include font-size(14px);
            font-family: "Rubik";
            text-transform: uppercase;
            opacity: 1;
          }
          &:-moz-placeholder {
            /* Firefox 18- */
            color: #c39c34;
            text-align: center;
            @include font-size(14px);
            font-family: "Rubik";
            text-transform: uppercase;
            opacity: 1;
          }
        }
      }
    }
  }
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem /* 8px */ * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem /* 8px */ * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem /* 8px */ * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem /* 8px */ * var(--tw-space-y-reverse));
}