nav {
  position: fixed;
  z-index: 500;
  background-color: $color-white;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;
  @media (max-width: 992px) {
    padding-left: 10px;
    padding-right: 10px;
  }
  #logo {
    width: 13.8125rem;
    @media (max-width: 992px) {
      height: 21.5%;
      width: 100%;
    }
  }
}